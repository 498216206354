<template>
	<b-form-group class="toolbar">
		<b-button
			class="m-2 right toolbar-btn"
			variant="outline-light"
      @click="openModal('share-link')"
		>
			<b-icon icon="share" aria-hidden="true"></b-icon>
      <span class='btn-text'>{{ shareText }}</span>
    </b-button>
		<b-dropdown
			class="m-2 right"
      variant="outline-light"
    >
      <template #button-content>
        <b-icon icon="globe" aria-hidden="true"></b-icon>
        <span class='btn-text'>EN / 文</span>
      </template>
      <b-dropdown-item @click="changeLanguage('en-us')">English</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('fr')">Français</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('ko')">한국어</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('zh-cn')">简体中文</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('zh-tw')">繁體中文</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('ja')">日本語</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('ru')">Русский</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('es')">Español</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('de')">Deutsch</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('it')">Italiano</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('pt-br')">Português</b-dropdown-item>
      <b-dropdown-item @click="changeLanguage('pl')">Polski</b-dropdown-item>
    </b-dropdown>
    <div class="right query-tool">
      <div class="m-2 left mode_switch">
        <label :class="[isOnlineMode ? 'mode_select' : 'mode_unselect']">{{ onlineText }}</label>
        <label class="switch">
          <input
            type="checkbox"
            :checked="!isOnlineMode"
            @click="togglePlaymode"
          >
          <div class="slider round"/>
        </label>
        <label :class="[!isOnlineMode ? 'mode_select' : 'mode_unselect']">{{ offlineText }}</label>
      </div>
      <b-form-datepicker
        class="m-2 left datepicker"
        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }"
        :placeholder="selectDateText"
        calendar-width="350px"
        :min="minDate"
        :max="maxDate"
        :locale="language"
        v-model="selectedDate"
        dropright>
      </b-form-datepicker>
    </div>
    <share-modal
      ref="share-link"
      :language="language"
      :mode="isOnlineMode ? 'online' : 'offline'"
      :timestamp="selectedTimestamp"
    />
	</b-form-group>
</template>
<script>
import { parseParameters } from '@/utils/common'
import ShareModal from './modals/Share.vue'

export default {
  name: 'ToolBar',

  components: {
    ShareModal
  },

  data() {
    return {
      isOnlineMode: true,
      selectedDate: '',
      selectedTimestamp: null,
    }
  },

  props: {
    language: {
      type: String,
      required: true
    },
  },

  computed: {
    minDate () {
      return this.isOnlineMode ? new Date(2023, 10, 1) : new Date(2023, 1, 1);
    },

    maxDate () {
      return this.isOnlineMode ? new Date() : new Date(2025, 12, 31);
    },

    onlineText () {
      // TODO: use i18n to handle differe language display
      switch(this.language) {
        case 'fr':
          return 'En ligne';
        case 'zh-cn':
          return '在线';
        case 'zh-tw':
          return '在線';
        case 'ko':
          return '온라인';
        case 'de':
          return 'Online';
        case 'es':
          return 'En línea';
        case 'ru':
          return 'Онлайн';
        case 'ja':
          return 'オンライン';
        case 'it':
          return 'Online';
        case 'pt-br':
          return 'On-line';
        case 'pl':
          return 'Online';
        default:
          return 'Online';
      }
    },

    offlineText () {
      // TODO: use i18n to handle differe language display
      switch(this.language) {
        case 'fr':
          return 'Hors ligne';
        case 'zh-cn':
          return '离线';
        case 'zh-tw':
          return '離線';
        case 'ko':
          return '오프라인';
        case 'de':
          return 'Offline';
        case 'es':
          return 'En local';
        case 'ru':
          return 'Оффлайн';
        case 'ja':
          return 'オフライン';
        case 'it':
          return 'Offline';
        case 'pt-br':
          return 'Off-line';
        case 'pl':
          return 'Offline';
        default:
          return 'Offline';
      }
    },

    shareText () {
      // TODO: use i18n to handle differe language display
      switch(this.language) {
        case 'fr':
          return 'Partager';
        case 'zh-cn':
          return '分享';
        case 'zh-tw':
          return '分享';
        case 'ko':
          return '공유하다';
        case 'de':
          return 'Aktie';
        case 'es':
          return 'Compartir';
        case 'ru':
          return 'Делиться';
        case 'ja':
          return '共有';
        case 'it':
          return 'Condividere';
        case 'pt-br':
          return 'Compartilhar';
        case 'pl':
          return 'Udział';
        default:
          return 'Share';
      }
    },

    selectDateText () {
      // TODO: use i18n to handle differe language display
      switch(this.language) {
        case 'fr':
          return 'Choisissez une date';
        case 'zh-cn':
          return '选择日期';
        case 'zh-tw':
          return '選擇日期';
        case 'ko':
          return '날짜를 선택하세요';
        case 'de':
          return 'Datum auswählen';
        case 'es':
          return 'Elige una fecha';
        case 'ru':
          return 'Выберите дату';
        case 'ja':
          return '日付を選択してください';
        case 'it':
          return 'Scegli una data';
        case 'pt-br':
          return 'Escolha uma data';
        case 'pl':
          return 'Wybierz datę';
        default:
          return 'Choose a date';
      }
    },
  },

  created() {
    const paramStr = window.location.search;
    if (paramStr?.length > 0) {
      const params = parseParameters(paramStr);
      if (params['ts']) {
        try {
          this.selectedTimestamp = parseInt(params['ts']) * 1000;
          this.$emit('change-date', this.selectedTimestamp);
        } catch (error) {
          console.error('Cannot parse timestamp: ', error);
        }
      }
      if (params['mode'] === 'offline') {
        this.togglePlaymode();
      }
    }
  },

  watch: {
    selectedDate(newDate) {
      if (newDate) {
        const date = new Date(newDate);
        this.selectedTimestamp = date.getTime() + date.getTimezoneOffset() * 60 * 1000;
        this.$emit('change-date', this.selectedTimestamp);
      } else {
        this.selectedTimestamp = null;
        this.$emit('change-date', null);
      }
    },
  },

  methods: {
    togglePlaymode () {
      this.isOnlineMode = !this.isOnlineMode;
      const date = this.selectedDate ? new Date(this.selectedDate) : new Date();
      if (date > this.maxDate || date < this.minDate) {
        this.selectedDate = '';
      }
      this.$emit('change-mode', this.isOnlineMode ? 'online' : 'offline');
    },

    changeLanguage (language) {
      console.log('change language to: ' + language);
      localStorage.setItem('language', language);
      this.$emit('change-language', language);
    },

    openModal (id) {
      this.$refs[id].show();
    },
  },
}
</script>
<style>
.toolbar {
  width: 75%;
  max-width: 900px;
  margin: auto;
}

@media (max-width: 600px) {
  .toolbar {
    width: 90%;
  }
}

.left {
	float: left;
}

.right {
	float: right;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0px 10px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #888;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #888;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.mode_select {
  color: white;
}

.mode_unselect {
  color: gray;
}

.mode_switch {
	display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
}

.datepicker {
	width: 45%!important;
}

.query-tool {
  width: 60%;
}

.toolbar-btn {
  width: 18%;
}

@media (max-width: 980px) {
  .query-tool {
    width: 100%;
  }

  .toolbar-btn {
    width: unset;
  }
}

.btn-text {
	display: inline-block;
	margin-left: 5px;
	margin-right: 2px;
  max-width: 75px;
}

@media (max-width: 980px) {
  .btn-text {
    display: none;
  }
}
</style>


