import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

const devMode = false;
const DEFAULT_BASE_URL = devMode ? '' : 'https://api.d2tz.info';
const SITE_MAP = {'us': 'https://api.d2tz.info', 'asia': 'https://asia.d2tz.info', 'eu': 'https://eu.d2tz.info'}
const ALL_SITE_URLS = devMode ? [''] : Object.keys(SITE_MAP).map(key => SITE_MAP[key]);
const cachedToken = {};

function apiRequest(opt) {
  if (!opt) {
    return;
  }
  const options = {
    method: opt.method || 'get',
    url: opt.url,
    headers: {
      Turnstile: opt.turnstileToken,
      Idempotency: opt.idempotencyKey,
    }
  }
  if (options.method === 'get') {
    options.params = opt.params || {}
  } else if (options.method === 'post' || options.method === 'put' || options.method === 'delete') {
    options.data = opt.params || {}
  }
  return axios(options).then(res => {
    if (res && res.data) {
      res.data.url = opt.url
      return res.data
    } else {
      return res;
    }
  }).catch(error => {
    if (!error) {
      console.log('unknown error')
      return null
    }
    if (error.response && error.response.status && error.response.status >= 500) {
      console.log('Failed to process request. Internal Server Error for ' + opt.url)
    } else if (error.response && error.response.status && error.response.status === 401) {
      throw 'unauthorized request'
    } else {
      throw error
    }
  })
}

function getIdempotencyKey(token) {
  if (token in cachedToken) {
    return cachedToken[token];
  }
  const idempotencyKey = uuidv4();
  cachedToken[token] = idempotencyKey;
  return idempotencyKey;
}

export function getData(mode, start=null, end=null, region=null, turnstileToken=null) {
  const base_url = getBaseUrl(region);
  const params = { mode };
  if (start) {
    params.start = start;
  }
  if (end) {
    params.end = end;
  }
  return apiRequest({
    url: `${base_url}/terror_zone`,
    method: 'get',
    params: params,
    turnstileToken: turnstileToken,
    idempotencyKey: getIdempotencyKey(turnstileToken),
  });
}

export function getRegionByUrl(url) {
  return Object.keys(SITE_MAP).find(key => url.startsWith(SITE_MAP[key]));
}

export function getDataFromAllSites(mode, start=null, end=null, turnstileToken=null) {
  let result = [];
  const params = { mode };
  if (start) {
    params.start = start;
  }
  if (end) {
    params.end = end;
  }
  const idempotencyKey = getIdempotencyKey(turnstileToken);
  for (const url of ALL_SITE_URLS) {
    result.push(apiRequest({
      url: `${url}/terror_zone`,
      method: 'get',
      params: params,
      turnstileToken: turnstileToken,
      idempotencyKey: idempotencyKey,
    }));
  }
  return result;
}

export function contactUs(contact, title, type, context, region=null) {
  const base_url = getBaseUrl(region);
  return apiRequest({
    url: `${base_url}/contact`,
    method: 'post',
    params: {
      "contact": contact,
      "title": title,
      "type": type,
      "context": context
    }
  });
}

export function getPublicRooms(region=null) {
  const base_url = getBaseUrl(region);
  return apiRequest({
    url: `${base_url}/public_room`,
    method: 'get'
  });
}

export function announceRoom(room, region=null) {
  const base_url = getBaseUrl(region);
  return apiRequest({
    url: `${base_url}/public_room`,
    method: 'post',
    params: {
      "region": room.region,
      "platform": room.platform,
      "ladder": room.ladder,
      "mode": room.mode,
      "name": room.name,
      "password": room.password
    }
  });
}

export function getBaseUrl(region=null) {
  if (!region) {
    return DEFAULT_BASE_URL;
  }
  return SITE_MAP[region] || DEFAULT_BASE_URL;
}


export function getRegion () {
  const region = localStorage.getItem('region');
  const fetch_all_ts = localStorage.getItem('fetch_all_ts');
  if (!region || !fetch_all_ts) {
    return null;
  }
  const now = Date.now();
  if (now - fetch_all_ts > 30 * 24 * 60 * 60 * 1000) {
    localStorage.removeItem('region');
    localStorage.removeItem('fetch_all_ts');
    return null;
  }
  return region;
}