<template>
  <div class="footer">
    <div class="footer-area">
      <img class="mx-4 footer-image" width="120" height="120" alt="Diablo II: Resurrected" src="../assets/footer.png">
      <div>
        <div class="footer-row">
          <div class="inline">
            <b>Feedback</b>
            <b-link aria-label="Contact Us" @click="open('contact-us-modal')">Contact Us</b-link>
          </div>
          <div class="inline">
            <b> Email </b>
            <a href="mailto:support@d2tz.info">support@d2tz.info</a>
          </div>
        </div>
        <div class="footer-row">
          <b>2023-2025 D2TZ All Right Reserved</b>
        </div>
      </div>
    </div>
    <contact-us-modal ref="contact-us-modal" @success="showSuccessContactUs"/>
    <report-error-modal ref="report-error-modal" @success="showSuccessReportError"/>
    <report-next-terror-zone-modal ref="report-next-tz-modal" @success="showSuccessReportNextTZ"/>
    <public-room ref="public-room-sidebar"/>
    <b-modal
      v-model="showMsgModal"
      :title="msgTitle"
      header-bg-variant="dark"
      header-text-variant="light"
      body-bg-variant="dark"
      body-text-variant="light"
      footer-bg-variant="dark"
      footer-text-variant="light"
      hideHeaderClose
      ok-only
    >
      <p class="my-2">{{ msgContent }}</p>
    </b-modal>
  </div>
</template>

<script>
import PublicRoom from './PublicRoom.vue'
import ContactUsModal from './modals/ContactUs.vue'
import ReportErrorModal from './modals/ReportError.vue'
import ReportNextTerrorZoneModal from './modals/ReportNextTZ.vue'

export default {
  name: 'TerrorZoneFooter',
  data() {
    return {
      showMsgModal: false,
      msgTitle: "",
      msgContent: "",
    }
  },

  components: {
    PublicRoom,
    ContactUsModal,
    ReportErrorModal,
    ReportNextTerrorZoneModal,
  },

  methods: {
    open (id) {
      this.$refs[id].show();
    },

    showSuccessContactUs () {
      const title = 'Request sent';
      const msg = "Thanks for contacting us! We'll touch with you later.";
      this.showMessage(title, msg);
    },

    showSuccessReportError () {
      const title = 'Report sent';
      const msg = "Thanks for reporting error! We'll handle it later.";
      this.showMessage(title, msg);
    },

    showSuccessReportNextTZ () {
      const title = 'Report sent';
      const msg = "Thanks for reporting the next terror zone!";
      this.showMessage(title, msg);
    },

    showToast (title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 5000,
        variant: 'success',
      });
    },

    showMessage (title, message) {
      this.msgTitle = title;
      this.msgContent = message;
      this.showMsgModal = true;
    },
  },
}
</script>

<style scoped>
.inline {
  display: inline-block;
}
.footer {
  background-color: #202224;
  height: 200px;
  color: #fff;
  padding: 20px 0px;
}
.footer-area {
  display: flex;
  justify-content: space-around;
  width: 80%;
  max-width: 650px;
  margin: auto;
}
.footer-row {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.footer-row b {
  margin: 5px;
}
.footer-row a {
  margin: 10px;
  color: #e4e6eb;
}
.footer-image {
  display: block;
}
@media (max-width: 600px) {
  .footer-image {
    display: none;
  }
  .footer-area {
    width: 100%;
  }
}
</style>
