<template>
  <b-modal
    v-model="visible"
    :title="title"
    header-bg-variant="dark"
    header-text-variant="light"
    body-bg-variant="dark"
    body-text-variant="light"
    footer-bg-variant="dark"
    footer-text-variant="light"
    ok-only
    :ok-title="closeText"
    ok-variant="secondary"
    hideHeaderClose
  >
    <b-input 
      v-on:focus="$event.target.select()" 
      ref="website_link" 
      class="url-input mb-2 inline"
      readonly 
      :value="link"
    />
    <b-button
      id="copy-btn"
      variant="primary"
      class="mx-2 inline"
      @click="copy">
        <b-icon icon="files"></b-icon>
    </b-button>
    <b-tooltip target="copy-btn" triggers="hover">
      {{ tooltipText }}
    </b-tooltip>
  </b-modal>
</template>

<script>

export default {
  name: 'ShareLinkModal',

  data() {
    return {
      visible: false,
      tooltipText: 'Copy',
    }
  },

  props: {
    language: {
      type: String,
      required: true
    },

    mode: {
      type: String,
      default: 'online'
    },

    timestamp: {
      type: Number,
      default: null
    },
  },

  computed: {
    title () {
      // TODO: use i18n to handle differe language display
      switch(this.language) {
        case 'fr':
          return 'Partagez le lien';
        case 'zh-cn':
          return '分享链接';
        case 'zh-tw':
          return '分享連結';
        case 'ko':
          return '링크 공유';
        case 'de':
          return 'Teilen Sie den Link';
        case 'es':
          return 'Comparte el enlace';
        case 'ru':
          return 'Поделитесь ссылкой';
        case 'ja':
          return 'リンクを共有する';
        case 'it':
          return 'Condividi il collegamento';
        case 'pt-br':
          return 'Compartilhe o link';
        case 'pl':
          return 'Udostępnij link';
        default:
          return 'Share the link';
      }
    },

    copyText () {
      // TODO: use i18n to handle differe language display
      switch(this.language) {
        case 'fr':
          return 'Copie';
        case 'zh-cn':
          return '复制';
        case 'zh-tw':
          return '複製';
        case 'ko':
          return '복사';
        case 'de':
          return 'Kopie';
        case 'es':
          return 'Copiar';
        case 'ru':
          return 'Копировать';
        case 'ja':
          return 'コピー';
        case 'it':
          return 'Copia';
        case 'pt-br':
          return 'Cópia';
        case 'pl':
          return 'Kopia';
        default:
          return 'Copy';
      }
    },

    copiedText () {
      // TODO: use i18n to handle differe language display
      switch(this.language) {
        case 'fr':
          return 'Copié';
        case 'zh-cn':
          return '已复制';
        case 'zh-tw':
          return '已複製';
        case 'ko':
          return '복사됨';
        case 'de':
          return 'Kopiert';
        case 'es':
          return 'Copiado';
        case 'ru':
          return 'Скопировано';
        case 'ja':
          return 'コピーされました';
        case 'it':
          return 'Copiato';
        case 'pt-br':
          return 'Copiado';
        case 'pl':
          return 'Skopiowano';
        default:
          return 'Copied';
      }
    },

    closeText () {
      // TODO: use i18n to handle differe language display
      switch(this.language) {
        case 'fr':
          return 'Fermer';
        case 'zh-cn':
          return '关闭';
        case 'zh-tw':
          return '關閉';
        case 'ko':
          return '닫다';
        case 'de':
          return 'Schließen';
        case 'es':
          return 'Cerca';
        case 'ru':
          return 'Закрывать';
        case 'ja':
          return '近い';
        case 'it':
          return 'Vicino';
        case 'pt-br':
          return 'Fechar';
        case 'pl':
          return 'Zamknąć';
        default:
          return 'Close';
      }
    },

    link () {
      let url = 'https://www.d2tz.info/';
      const parameters = {};
      if (this.mode === 'offline') {
        parameters['mode'] = 'offline';
      }
      if (this.timestamp) {
        parameters['ts'] = (this.timestamp / 1000).toString();
      }
      if (Object.keys(parameters).length > 0) {
        url += '?';
        for (const [key, value] of Object.entries(parameters)) {
          url += `${key}=${value}&`;
        }
        url = url.slice(0,-1);
      }
      return url;
    }
  },

  created() {
    this.tooltipText = this.copyText;
  },

  watch: {
    language() {
      this.tooltipText = this.copyText;
    },
  },

  methods: {
    show () {
      this.visible = true;
    },

    hide () {
      this.visible = false;
      this.tooltipText = this.copyText;
    },

    copy () {
      this.$refs.website_link.focus();
      document.execCommand('copy');
      this.tooltipText = this.copiedText;
      setTimeout(() => { this.tooltipText = this.copyText }, 3000);
    },
  },
}
</script>
<style>
.inline {
  display: inline!important;
}
.url-input {
  width: 85%!important;
  vertical-align: top;
}
</style>