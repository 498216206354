<template>
  <div id="app">
    <TerrorZoneHeader :language="language" @change-language="onLanguageChanged"/>
    <ToolBar class="mb-4" 
      :language="language"
      @change-language=onLanguageChanged
      @change-date=onDateChanged
      @change-mode=onModeChanged
    />
    <div v-if="errorMessage">
      <h1>Error</h1>
      <p>
        {{ errorMessage }}
      </p>
    </div>
    <div v-else-if="turnstileToken || !antiBot">
      <TerrorZoneQueryTable
        v-if="playMode == 'online'"
        :language="language"
        :play-mode="playMode"
        :selected-timestamp="selectedTimestamp"
        :turnstile-token="turnstileToken"
        @error="errorMessage = $event"
      />
      <TerrorZoneWeekTable
        v-else-if="playMode == 'offline'"
        :language="language"
        :play-mode="playMode"
        :selected-timestamp="selectedTimestamp"
        :turnstile-token="turnstileToken"
        @error="errorMessage = $event"
      />
    </div>
    <vue-turnstile
      v-else
      site-key="0x4AAAAAAA4WXTV570zHkMsD"
      theme="dark"
      auto-reset
      @verified="turnstileToken = $event"
    />
    <TerrorZoneFooter class="mt-4"/>
  </div>
</template>

<script>
import VueTurnstile from '@gaviti/vue-turnstile';
import { supportedLanguages } from '@/utils/tz_data'
import { parseParameters } from '@/utils/common'
import TerrorZoneHeader from './components/Header.vue'
import ToolBar from './components/ToolBar.vue'
import TerrorZoneFooter from './components/Footer.vue'
import TerrorZoneQueryTable from './components/TerrorZoneQueryTable.vue'
import TerrorZoneWeekTable from './components/TerrorZoneWeekTable.vue'

const ANTI_BOT = false;

export default {
  name: 'App',

  components: {
    VueTurnstile,
    TerrorZoneHeader,
    ToolBar,
    TerrorZoneQueryTable,
    TerrorZoneWeekTable,
    TerrorZoneFooter,
  },

  data() {
    return {
      language: null,
      selectedTimestamp: null,
      playMode: 'online',
      turnstileToken: null,
      errorMessage: null,
      antiBot: ANTI_BOT,
    }
  },

  created() {
    const paramStr = window.location.search;
    if (paramStr?.length > 0) {
      const params = parseParameters(paramStr);
      if (!!params['l'] && supportedLanguages.includes(params['l'])) {
        this.language = params['l'];
      }
      if (!!params['language'] && supportedLanguages.includes(params['language'])) {
        this.language = params['language'];
      }
    }
    if (!this.language) {
      this.language = localStorage.getItem('language') || 'en-us';
    }
  },

  methods: {
    onLanguageChanged (language) {
      if (supportedLanguages.includes(language)) {
        this.language = language;
      }
    },

    onDateChanged (newTimestamp) {
      this.selectedTimestamp = newTimestamp;
    },

    onModeChanged (mode) {
      this.playMode = mode;
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  background-color: #000;
}

body {
  background-color: #000!important;
}
</style>
