<template>
  <div class="mb-5 tz-table-container">
    <div v-if="loading">
      <b-spinner
        class="tz-table-loading"
        variant="light"
      />
      <p>
        (If there is no response for a long time, please clear the browser cache and force refresh the page) 
      </p>
    </div>
    <table
      v-else
      class="main-table"
    >
      <thead>
        <tr>
          <th
            v-for="(headText, index) in fields"
            :key="index"
            class="p-2"
          >
            {{ headText }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in items" :key="rowIndex">
          <td
            v-for="(d, headIndex) in fields"
            :key="headIndex"
            class="p-2"
            @mouseover="setWatchIcon(rowIndex, headIndex, true)"
            @mouseleave="setWatchIcon(rowIndex, headIndex, false)"
          >
            <template v-if="headIndex === 0">
             {{ row[d] }}
            </template>
            <template v-else-if="row[d]">
              <div
                v-for="(name, nameIndex) in row[d].nameList || []"
                :key="nameIndex"
              >
                <div
                  v-if="!hiddenTZs.includes(row[d].id) || row[d].showWatchIcon"
                  :class="{'hiddenText' : hiddenTZs.includes(row[d].id)}"
                >
                  {{ name[language] }}
                </div>
              </div>
              <b-badge
                v-if="row[d].showNowBadge"
                class="badge-pill badge-success"
              >
                Now
              </b-badge>
              <div v-if="row[d].showWatchIcon">
                <b-icon
                  v-if="hiddenTZs.includes(row[d].id)"
                  icon="eye-fill"
                  @click="showTZ(row[d].id)"
                />
                <b-icon
                  v-else
                  icon="eye-slash-fill"
                  @click="hideTZ(row[d].id)"
                />
              </div>
              <div v-else>
                <b-icon icon="blank"/>
              </div>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { tzData } from '@/utils/tz_data'
import { getDataFromAllSites, getData, getRegionByUrl, getRegion } from '@/utils/api'

export default {
  name: 'TerrorZoneQueryTable',

  data() {
    return {
      fields: ['', '', '', '', '', '', '', ''],
      items: [],
      loading: false,
      maxTS: 0,
      selectedDate: null,
      hiddenTZs: [],
    }
  },

  props: {
    language: {
      type: String,
      required: true
    },
    playMode: {
      type: String,
      required: true
    },
    selectedTimestamp: {
      type: Number,
      default: null,
    },
    turnstileToken: {
      type: String,
      required: true
    },
  },

  created() {
    this.fetch();
    this.hiddenTZs = localStorage.getItem('hidden_tz_list');
    if (this.hiddenTZs) {
      this.hiddenTZs = JSON.parse(this.hiddenTZs);
    } else {
      this.hiddenTZs = [];
    }
  },

  watch: {
    turnstileToken(newToken) {
      if (newToken) {
        this.refetch();
      }
    },
    selectedTimestamp() {
      this.refetch();
    }
  },

  methods: {
    fetch () {
      this.loading = true;
      let start = null, end = null;
      if (this.selectedTimestamp) {
        const midDate = parseInt(this.selectedTimestamp / 1000);
        start = midDate - 3 * 24 * 60 * 60;
        end = midDate + 4 * 24 * 60 * 60 - 1;
        this.initFields(start * 1000);
      } else {
        const startTs = new Date().getTime()- 3 * 24 * 60 * 60 * 1000;
        this.initFields(startTs);
      }
      const region = getRegion();
      if (region) {
        getData(this.playMode, start, end, region, this.turnstileToken)?.then(this.initTerrorZone)?.catch(this.handleErrors);
      } else {
        getDataFromAllSites(this.playMode, start, end, this.turnstileToken)?.forEach(resp => resp?.then(this.initTerrorZone))?.catch(this.handleErrors);
      }
    },

    refetch () {
      this.maxTS = 0;
      this.fetch();
    },

    initFields (firstDateTs) {
      this.fields = [''];
      for (let i = 0; i < 7; i++) {
        const dt = new Date(firstDateTs + i * 24 * 60 * 60 * 1000);
        this.fields.push(dt.toLocaleDateString())
      }
    },

    initTerrorZone (data) {
      if (!data) {
        console.log('Failed to get data.');
        return;
      }
      const isOldData = !data['data'].find(x => x['time'] * 1000 > this.maxTS);
      if (isOldData) {
        console.log('Skip update since already got the data.');
        return;
      }
      this.parseData(data['data']);
      this.loading = false;
      let region = getRegion();
      if (!region) {
        region = getRegionByUrl(data['url']);
        localStorage.setItem('region', region);
        localStorage.setItem('fetch_all_ts', Date.now());
      }
    },

    parseData (data) {
      this.items = [];
      for (let i = 0; i <= 23; i++) {
        const timeStr = i.toString() + ':00';
        const item = { '':  timeStr };
        for (let j = 1; j <= 7; j++) {
          const date = this.fields[j];
          item[date] = { 'nameList': [] };
        }
        this.items.push(item)
      }
      const timeNow = new Date();
      for (const item of data) {
        const ts = parseInt(item['time'] / 60) * 60 * 1000;
        if (ts > this.maxTS) {
          this.maxTS = ts;
        }
        const timeTZ = new Date(ts);
        const dateStr = timeTZ.toLocaleDateString();
        const hours = timeTZ.getHours();
        if (!this.fields.includes(dateStr)) {
          continue;
        }
        let nameList = [];
        if (Array.isArray(item['zone'])) {
          for (let zoneId in item['zone']) {
            const tzItem = tzData.find(x => x['id'] === zoneId);
            if (!tzItem) {
              continue;
            }
            nameList.push(tzItem['name']);
          }
        } else {
          const tzItem = tzData.find(x => x['id'] === item['zone']);
          if (!tzItem) {
            return null;
          }
          nameList = [tzItem['name']];
        }
        const tzDuration = 60 * 60 * 1000;
        const showNowBadge = ts <= timeNow.getTime() && timeNow.getTime() < ts + tzDuration;
        this.items[hours][dateStr] = { 
          'id': item['zone'],
          'nameList': nameList,
          'showNowBadge': showNowBadge,
          'showWatchIcon': false,
        };
      }
      return this.items;
    },

    handleErrors (errors) {
      if (errors === 'unauthorized request') {
        const errorMessage = 'Authentication failed. Unable to process request. Please refresh the page.';
        this.loading = false;
        this.$emit('error', errorMessage);
      } else {
        console.log(errors)
      }
    },

    setWatchIcon (rowIndex, colIndex, value) {
      if (colIndex < 1) {
        return
      }
      const dateStr = this.fields[colIndex];
      this.items[rowIndex][dateStr].showWatchIcon = value;
    },

    showTZ (tzId) {
      const index = this.hiddenTZs.indexOf(tzId);
      if (index >= 0) {
        this.hiddenTZs.splice(index, 1);
      }
      localStorage.setItem('hidden_tz_list', JSON.stringify(this.hiddenTZs));
    },

    hideTZ (tzId) {
      const index = this.hiddenTZs.indexOf(tzId);
      if (index < 0) {
        this.hiddenTZs.push(tzId);
      }
      localStorage.setItem('hidden_tz_list', JSON.stringify(this.hiddenTZs));
    },
  },
}
</script>

<style scoped>
.tz-table-loading {
  margin: 10vh;
}
.hiddenText {
  color: #777;
}
.main-table {
  width: 75%;
  max-width: 900px;
  margin: auto;
  background-color: #212529;
  color: #bbb;
}
.main-table table, th, td {
  border: 1px solid #444;
  border-collapse: collapse;
}
.main-table th {
  position: sticky;
  top: 0px;
  color: #fff;
  background: #333;
}
.main-table td:nth-child(1) {
  color: #ddd;
  background: #222;
}
@media (max-width: 900px) {
  .main-table th:nth-child(2), th:nth-child(8) {
    display: none!important;
  }
  .main-table td:nth-child(2), td:nth-child(8) {
    display: none!important;
  }
}
@media (max-width: 600px) {
  .main-table {
    width: 90%;
  }
  .main-table th:nth-child(2), th:nth-child(3), th:nth-child(7), th:nth-child(8) {
    display: none!important;
  }
  .main-table td:nth-child(2), td:nth-child(3), td:nth-child(7), td:nth-child(8) {
    display: none!important;
  }
}
.badge-success {
    color: #fff;
    background-color: #28a745;
}
.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}
.badge-danger {
    color: #fff;
    background-color: #dc3545;
}
</style>
